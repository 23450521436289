import React, { useEffect } from 'react';
import { FloatingHelper, ToggleSwitch, Loader, MarketingLayout, Input, Dropdown, Heading, Modal, MessageModalLayout, Text, Card, FloatingNotification, Box, Page, Button, Cell, Layout, WixDesignSystemProvider, PageSection, Badge, AnnouncementModalLayout, FormField, CopyClipboard, ColorInput, Tooltip, CounterBadge, Image, IconButton, BrowserPreviewWidget } from '@wix/design-system';
import "@wix/design-system/styles.global.css";
import * as Icons from '@wix/wix-ui-icons-common';
import './App.css';
import { ReactTagManager } from 'react-gtm-ts';
import IntercomLogo from './intercom-logo.png';
const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
}

const app_base = [{ id: "https://api-iam.intercom.io", value: "US (default)" },
{ id: "https://api-iam.eu.intercom.io", value: "EU" },
{ id: "https://api-iam.au.intercom.io", value: "Australia" }];

const alignment = [
  { id: "right", value: "Right (default)" },
  { id: "left", value: "Left" }
]

function App() {
  ReactTagManager.init(tagManagerArgs)

  /* Wix Owner App - Start */
  const isOwnerApp = new URLSearchParams(window.location.search).get('openedIn') === 'ownerApp';
  const isPremiumUpgradeDisallowed = new URLSearchParams(window.location.search).get('isPremiumUpgradeAllowed') === 'false';
  /* Wix Owner App - End */

  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isWorkspaceUrlHelperOpened, setIsWorkspaceUrlHelperOpened] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);
  const [isConnected, setIsConnected] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: ""
    }
  });
  const [config, setConfig] = React.useState({} as any)

  useEffect(() => {
    const state = new URLSearchParams(window.location.search).get('state');
    if (!state) {
      setIntercomIdentity();
      setInstance();
      getSettings();
    }
  }, [])

  useEffect(() => {
    if (Object.keys(config).length > 0 && isLoaded) {
      setSettings();
    }
  }, [config]);

  useEffect(() => {
    if (isLoaded) {
      setSettings();
    }
    if (isEnabled && isLoaded) {
      setIsUpgradeModalOpen(true);
    }
  }, [isEnabled]);

  const onStorageUpdate = (e: any) => {
    const { key, newValue } = e;
    if (key === "connectionState") {
      setIsConnected(newValue === "true");
    }
  };

  useEffect(() => {
    setIsConnected((localStorage.getItem("connectionState") || "") === 'true');
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);

  const token = new URLSearchParams(window.location.search).get('token');
  const instance = new URLSearchParams(window.location.search).get('instance')

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=1626b279-f73b-4673-bce6-c29259bab1ed&redirectUrl=https://certifiedcode.editorx.io/intercom/_functions/@certifiedcode/base-backend/auth`;
  }

  if (!!token) {
    return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Content>
          <Box height={"90vh"} direction="vertical" verticalAlign="middle">
            <Loader text="Loading" />
          </Box>
        </Page.Content>
      </Page></WixDesignSystemProvider>
  }

  const BASE_URL = `https://certifiedcode.editorx.io/intercom/_functions`

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash
          });
        }
      })
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {
        // setIsModalOpen(true);
      })
  }

  function setSettings() {
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        "Authorization": instance || ""
      },
      body: JSON.stringify({
        config,
        isEnabled
      })
    })
      .then(() => {

      })
      .catch(() => {
        // setIsModalOpen(true);
      })
  }

  function getSettings() {
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        setConfig(data.config || {});
        setIsEnabled(data.isEnabled);
        setIsConnected(data.isConnected);
        setIsLoaded(true);
      })
      .catch(() => {
        // setIsModalOpen(true);
      })
  }

  const isPremiumApp = false

  const isLiveApp = true

  const lang = new URLSearchParams(window.location.search).get('locale') || 'en';

  const state = new URLSearchParams(window.location.search).get('state');

  if (state === 'success') {
    localStorage.setItem("connectionState", "true");
    // window.close();
    return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Content>
          <Box height={"90vh"} direction="vertical" verticalAlign="middle">
            <Box align="center" direction="vertical">
              <Icons.StatusComplete size="54" />
              <Heading appearance="H3">Success</Heading>
              <Text size="medium">Connected to Intercom. You can close this window.</Text>
            </Box>
          </Box>
        </Page.Content>
      </Page></WixDesignSystemProvider>
  }

  if (state === 'error') {
    localStorage.setItem("connectionState", "false");
    // window.close();
    return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Content>
          <Box height={"90vh"} direction="vertical" verticalAlign="middle">
            <Box align="center" direction="vertical">
              <Icons.StatusWarning size="54" />
              <Heading appearance="H3">Error</Heading>
              <Text size="medium">Something went wrong, please try again later.</Text>
            </Box>
          </Box>
        </Page.Content>
      </Page></WixDesignSystemProvider>
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh' minWidth={0}>
        <Page.Header
          title={<Box gap={"SP2"} alignItems="middle">
            <Image borderRadius={0} width="40px" src={IntercomLogo} /><Box gap={0} alignItems="middle"><Heading>Intercom</Heading><Badge size="tiny" skin="neutralLight">via Certified Code</Badge></Box>
          </Box>}
        />
        <Page.Content>
          <Modal
            isOpen={isWorkspaceUrlHelperOpened}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setIsWorkspaceUrlHelperOpened(false)}
          >
            <AnnouncementModalLayout
              theme="standard"
              title="Find your workspace url in browser"
              primaryButtonText="Got it"
              primaryButtonOnClick={() => {
                setIsWorkspaceUrlHelperOpened(false)
              }}
            >
              <BrowserPreviewWidget browserBarSize="size24" skin="gradient">
                <Box width="250px" height="100px" backgroundColor="Y30" />
              </BrowserPreviewWidget>
              <Text>Check your workspace url from browser after logging in to Intercom. </Text>
              <Text>Then, extract your app id: <code>https://app.intercom.com/a/inbox/<b>MY_APP_ID</b>/inbox/</code>, your app id is <code><b>MY_APP_ID</b></code>.</Text>
            </AnnouncementModalLayout>
          </Modal>
          <Modal
            isOpen={isUpgradeModalOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setIsUpgradeModalOpen(false)}
          >
            <AnnouncementModalLayout
              theme="standard"
              title="Great! Intercom Chat Widget is now connected."
              primaryButtonText="Do it now"
              primaryButtonOnClick={() => {
                window.open(`https://app.intercom.com/a/apps/_/messenger`)
              }}
              onCloseButtonClick={() => setIsUpgradeModalOpen(false)}
            >
              <Text>
                {<Text>Next, whitelist your site domain <CopyClipboard value={((instanceData as any)?.site?.url || "").replace("https://", "")}>{({ isCopied, copyToClipboard, reset }) => (<Tooltip content={isCopied ? "Copied!" : "Click to copy"}><code onClick={() => (isCopied ? reset() : copyToClipboard())}>{((instanceData as any)?.site?.url || "").replace("https://", "")}</code></Tooltip>)}</CopyClipboard> on Intercom setting to see this widget on live site.</Text>}
              </Text>
            </AnnouncementModalLayout>
          </Modal>
          <Modal
            isOpen={isModalOpen}
            screen="desktop"
          ><MessageModalLayout
              primaryButtonText="Refresh"
              primaryButtonOnClick={() => window.location.reload()}
              title="Something went wrong"
              content={
                <Text>We are having some issues when loading this app, please refresh this page to continue.</Text>
              }
            />
          </Modal>
          <Layout>
            <Cell span={8}>
              <Card>
                <Card.Header
                  title="Show Intercom widget on your site"
                  subtitle="By turning it on, Intercom chat widget will be shown on your live site."
                  suffix={<ToggleSwitch size="small" checked={isEnabled && !!config?.app_id} disabled={!config?.app_id} onChange={() => { setIsEnabled(!isEnabled); }} />}
                />
                {isEnabled ? <FloatingNotification
                  fullWidth
                  showCloseButton={false}
                  text="Chat widget not showing on live site?"
                  textButtonProps={{ label: 'See why', onClick: () => setIsUpgradeModalOpen(true) }}
                /> : <Card.Divider />}
                <Card.Content>
                  <Box gap={"SP2"} direction="vertical">
                    <FormField label="App ID" required>
                      <Box width={'100%'} gap="SP2"><Input onChange={(v) => {
                        setConfig({ ...config, app_id: v.target.value })
                      }}
                        value={config?.app_id}>
                      </Input>
                        <IconButton
                          onClick={() => {
                            setIsWorkspaceUrlHelperOpened(true)
                          }}
                          // size="small"
                          skin="inverted"
                          priority="secondary"
                          tooltip="Help"
                        ><Icons.Help /></IconButton></Box>
                    </FormField>
                    <FormField label="App Base" infoContent={`For customers who are using Regional Data Hosting for Intercom, there is an additional parameter to set, to ensure your Messenger is pointing to your Regional workspace. The snippet below contains this additional "api_base" parameter to connect to the Messenger's Regional endpoint.`}>
                      <Dropdown
                        onSelect={(v) => {
                          setConfig({ ...config, app_base: v.id })
                        }}
                        options={app_base}
                        selectedId={app_base.find(v => v.id === (config?.app_base || "https://api-iam.intercom.io"))?.id}
                      >
                      </Dropdown>
                    </FormField>
                    <FormField label="Alignment" infoContent={`Dictate the alignment of the default launcher icon to be on the left/right.`}>
                      <Dropdown onSelect={(v) => {
                        setConfig({ ...config, alignment: v.id })
                      }}
                        options={alignment}
                        selectedId={alignment.find(v => v.id === (config?.alignment || "right"))?.id}
                      >
                      </Dropdown>
                    </FormField>
                    <FormField label="Vertical Padding" infoContent={`Move the default launcher icon vertically. Padding from bottom of screen. Minimum value: 20. Does not work on mobile.`}>
                      <Input type="number" min={20}
                        onChange={(v) => {
                          setConfig({ ...config, vertical_padding: v.target.value })

                        }}
                        value={
                          config?.vertical_padding || 20
                        }>
                      </Input>
                    </FormField>
                    <FormField label="Horizontal Padding" infoContent={`Move the default launcher icon horizontally. Padding from right side of screen Minimum value: 20. Does not work on mobile.`}>
                      <Input type="number" min={20}
                        onChange={(v) => {
                          setConfig({ ...config, horizontal_padding: v.target.value })

                        }}
                        value={
                          config?.horizontal_padding || 20
                        }>
                      </Input>
                    </FormField>
                    <FormField label="Enforce display launcher" infoContent={`Toggle on will forcefully show the launcher icon`}>
                      <ToggleSwitch
                        size="large"
                        onChange={() => {
                          setConfig({ ...config, hide_default_launcher: !config?.hide_default_launcher })

                        }}
                        checked={config?.hide_default_launcher || false}
                      />
                    </FormField>
                    <FormField label="Session duration" infoContent={`Time in milliseconds for the Intercom session to be considered active.`}>
                      <Input type="number"
                        clearButton
                        onChange={(v) => {
                          setConfig({ ...config, session_duration: v.target.value.toString() })

                        }}
                        onClear={() => {
                          setConfig({ ...config, session_duration: "" })

                        }}
                        value={
                          config?.session_duration || ""
                        }>
                      </Input>
                    </FormField>
                    <FormField label="Action color" infoContent={`Used in button links and more to highlight and emphasise`}>
                      <ColorInput
                        value={config?.action_color || "#000000"}
                        addTooltipContent="Add Color"
                        popoverProps={{
                          animate: true,
                        }}
                        onChange={(v) => {
                          setConfig({ ...config, action_color: v })

                        }}
                      />
                    </FormField>
                    <FormField label="Background color" infoContent={`Used behind your team profile and other attributes`}>
                      <ColorInput
                        value={config?.background_color || "#FFFFFF"}
                        addTooltipContent="Add Color"
                        popoverProps={{
                          animate: true,
                        }}
                        onChange={(v) => {
                          setConfig({ ...config, background_color: v })
                        }}
                      />
                    </FormField>
                  </Box>
                </Card.Content>
              </Card>
            </Cell>
            <Cell span={4}>
              <Card>
                <Card.Header
                  title="Sync contacts, members and events to Intercom"
                  subtitle="By turning it on, all contacts, members and events will be synced to Intercom."
                />
                <Card.Divider />
                <Card.Content>
                  <Box gap={"SP2"} direction="vertical" align="center">
                    {!isConnected ? <Button
                      disabled={!instanceData?.instance?.instanceId}
                      // onClick={() => {setIsConnected(true)}}
                      as="a"
                      href={"https://app.intercom.com/oauth?client_id=a719e5ab-8ba8-4df3-b806-b13da6138353&state=" + instanceData?.instance?.instanceId}
                      target="_blank"
                    >Connect to Intercom</Button> : <Button skin="light"
                      onClick={() => { setIsConnected(false) }}
                    >Disconnect</Button>}
                    {isConnected ? <Box gap={"SP1"} alignItems="center">
                      <CounterBadge size="medium" skin="success">
                        <Icons.Check />
                      </CounterBadge>
                      <Text skin="success">Connected!</Text>
                    </Box> : <></>}
                  </Box>
                </Card.Content>
              </Card>
            </Cell>
            {/* <Cell>
              <PageSection title={lang === 'en' ? "What's next?" : "下一步是什么？"
              } showDivider />
            </Cell>
            <Cell span={12}>
              <Card>
                <MarketingLayout
                  title={lang !== "zh" ? "Get expert help on setting up Intercom" : "在设置Intercom上获得专家帮助"}
                  description={lang !== "zh" ? "Our experts will help you set up chatbot, create your first campaign and more." : "我们的专家将帮助您设置聊天机器人，创建您的第一个活动等。"}
                  actions={<Button onClick={() => {
                    window.Intercom("showNewMessage", lang !== 'zh' ? "I would like to get expert help setting up Intercom." : "我想获得专家帮助设置Intercom。"
                    )
                  }}>{lang !== 'zh' ? "Let us know" : "让我们知道"
                    }</Button>}
                  size="tiny"
                  badge={<Badge size="small">{lang !== 'zh' ? "Private Services" : "私人服务"
                  }</Badge>}
                />
              </Card>
            </Cell> */}
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
